import { Dispatch } from 'redux'
// Local
import { UPDATE_SELECTED, UPDATE_LIST, APPEND_LIST } from '../reducer/patient'
import { API_URL } from '../constant'
import { api } from './api'
import { searchGenerator } from './search'

export function clearSelected() {
  return {
    type: UPDATE_SELECTED,
    payload: {}
  }
}

export function updateList(list: any) {
  return {
    type: UPDATE_LIST,
    payload: list
  }
}

export function appendList(list: any) {
  return {
    type: APPEND_LIST,
    payload: list
  }
}

export type LoadPatientsProps = {
  query?: any
  limit?: number | undefined 
  offset?: number
  sort: string
  queryOverride?: any
  append?: any
  userRole: string
}

export async function loadPatients(
  props: LoadPatientsProps,
  dispatch: Dispatch<any>
) {
  const {
    query = undefined,
    limit = undefined,
    offset = undefined,
    sort,
    queryOverride = undefined,
    append = undefined,
    userRole
  } = props
  const params = searchGenerator(query, limit, offset, sort, queryOverride)
  return api.get(API_URL.GET_PATIENT_WITH_ROLE(userRole), { params }).then(({ data }) => {
    if (!append) {
      dispatch(updateList(data))
    } else {
      dispatch(appendList(data))
    }
  })
}
