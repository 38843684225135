import React, { Dispatch, SetStateAction } from 'react'
import { Button } from 'semantic-ui-react'

type ScheduleButtonProps = {
  encounterId: string
  label: string
  setIsModalShowing: Dispatch<SetStateAction<boolean>>
  setEncounterIdState: Dispatch<SetStateAction<string>>
  isScheduleCallBeingMade: boolean
}

function ScheduleButton(props: ScheduleButtonProps) {
  const {
    encounterId,
    label,
    setIsModalShowing,
    setEncounterIdState,
    isScheduleCallBeingMade
  } = props
  return (
    <Button
      compact
      content={ label }
      disabled={ isScheduleCallBeingMade }
      onClick={ () => {
        setEncounterIdState(encounterId)
        setIsModalShowing(true)
      } }
    />
  )
}

export default ScheduleButton
