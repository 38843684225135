/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Form, Grid, Icon, Popup, Segment, Select } from 'semantic-ui-react'
import concat from 'lodash/concat'
import { format } from 'date-fns'
import {
  API_URL,
  ENCOUNTER,
  FORMAT,
  LIST_PAGE_SIZE,
  LIST_MAX_PAGES,
  LOCK,
  PATIENT,
  REVIEW,
  ROLES,
  WORKLIST_VIEW
} from '../../../constant'
import { api } from '../../../action/api'
import { searchGenerator } from '../../../action/search'
import { updateUserWorklistFilters } from '../../../action/user'
import SubHeader from '../../common/subHeader'
import Filter from '../../common/filter'
import Table from '../../common/table'
import { patientMrn } from '../../common/patientUtils'
import { showVbcDisplay } from '../../common/currentUserUtils'
import ReviewStatusIcon from '../common/reviewStatusIcon'
import CdiEncounterStatusIcon from './cdiEncounterStatusIcon'
import { useCdiRestricted } from '../../common/roleUtils'
/**
 * Provides a worklist view of encounters for the CDI user role
 *
 * @param onSelect Callback function to execute when an encounter is selected
 *        arguments: Encounter ID
 * @param currentUser The current user
 */
class CdiWorklist extends Component {
  constructor(props) {
    super(props)

    this.state = {
      encounters: [],
      tenantId: this.props.currentUser.tenantId ? this.props.currentUser.tenantId : '',
      worklistView: WORKLIST_VIEW.CDI.WORKLIST,
      pagesDisplayed: 0,
      encounterSort: 'start asc',
      showFilter: false,
      queryFilters: {},
      currentUser: this.props.currentUser
    }
  }

  componentDidMount() {
    const { currentUser } = this.props
    const userFilters = currentUser.worklistFilters
    const encounterSort = userFilters.encounterSort ? userFilters.encounterSort : 'start asc'
    const view = userFilters.view ? userFilters.view : this.state.worklistView
    const offset = 0
    const query = {
      start: userFilters.start ? new Date(userFilters.start) : null,
      birthDate: userFilters.birthDate ? new Date(userFilters.birthDate) : null,
      firstName: userFilters.firstName ? userFilters.firstName : null,
      lastName: userFilters.lastName ? userFilters.lastName : null,
      patientOrgId: userFilters.patientOrgId ? userFilters.patientOrgId : null,
      appointmentType: userFilters.appointmentType ? userFilters.appointmentType : null,
      practitionerId: userFilters.practitionerId ? userFilters.practitionerId : null,
      patientRegion: userFilters.patientRegion ? userFilters.patientRegion : null,
      potentialConditionCount: userFilters.potentialConditionCount ? userFilters.potentialConditionCount : null,
      specialty: userFilters.specialty ? userFilters.specialty : null,
      vbcProgram: userFilters.vbcProgram ? userFilters.vbcProgram : null,
      mrn: userFilters.mrn ? userFilters.mrn : null
    }

    const showFilter = Boolean(query.start || query.birthDate ||
      query.firstName || query.lastName || query.patientOrgId ||
      query.appointmentType || query.practitionerId || query.patientRegion ||
      query.mrn || query.potentialConditionCount || query.specialty)

    this.setState({
      showFilter,
      encounterSort
    }, () => {
      this.fetchEncounters(view, offset, query)
    })
  }

  onWorklistViewSelect = (e, { value }) => {
    const view = value
    const query = this.state.queryFilters
    const userFilters = this.props.currentUser.worklistFilters
    userFilters.view = view
    this.props.updateUserWorklistFilters(this.props.currentUser, userFilters)
    this.fetchEncounters(view, 0, query)
  }

  worklistViewOptions = () => [
    {
      key: WORKLIST_VIEW.CDI.WORKLIST,
      text: 'Worklist',
      value: WORKLIST_VIEW.CDI.WORKLIST
    },
    {
      key: WORKLIST_VIEW.CDI.OPEN,
      text: 'Open',
      value: WORKLIST_VIEW.CDI.OPEN
    },
    {
      key: WORKLIST_VIEW.CDI.REVIEW_REQUEST,
      text: 'CDI Review Request',
      value: WORKLIST_VIEW.CDI.REVIEW_REQUEST
    },
    {
      key: WORKLIST_VIEW.CDI.READY_FOR_CDI_QA,
      text: 'Ready for CDI QA',
      value: WORKLIST_VIEW.CDI.READY_FOR_CDI_QA
    },
    {
      key: WORKLIST_VIEW.CDI.READY_FOR_PHYSICIAN,
      text: 'Ready for Physician',
      value: WORKLIST_VIEW.CDI.READY_FOR_PHYSICIAN
    },
    {
      key: WORKLIST_VIEW.CDI.LOCKED,
      text: 'Locked',
      value: WORKLIST_VIEW.CDI.LOCKED
    },
    {
      key: WORKLIST_VIEW.CDI.CANCELLED,
      text: 'Cancelled',
      value: WORKLIST_VIEW.CDI.CANCELLED
    }
  ]

  filterMetadata = () => {
    const { tenantId } = this.state
    const showVbcFilter = showVbcDisplay(this.props.currentUser)
    const metadata = [
      // row one
      [
        { placeholder: 'Patient MRN', name: 'mrn', width: 6, like: false },
        { placeholder: 'First Name', name: 'firstName', width: 5, like: true },
        { placeholder: 'Last Name', name: 'lastName', width: 5, like: true }
      ],
      // row two
      [
        { placeholder: 'Appointment Date', name: 'start', width: 6, date: true },
        { placeholder: 'Date of Birth', name: 'birthDate', width: 5, date: true },
        { placeholder: 'Practice', name: 'patientOrgId', width: 5, organizationSelect: true }
      ],
      // row three
      [
        { placeholder: 'Visit Type', name: 'appointmentType', width: 6, appointmentTypeSelect: true },
        { placeholder: 'Practitioner', name: 'practitionerId', width: 5, practitionerSelect: true },
        { placeholder: 'Region', name: 'patientRegion', width: 5, patientRegionSelect: true }
      ],
      // row four
      [
        { placeholder: 'Potential Opportunities', name: 'potentialConditionCount', width: 6, potentialCountSelect: true },
        { placeholder: 'Specialty', name: 'specialty', width: 5, specialtySelect: true }
      ]
    ]

    if (showVbcFilter) {
      metadata[3].push({
        placeholder: 'VBC Program',
        name: 'vbcProgram',
        width: 5,
        vbcProgramSelect: true
      })
    }

    return metadata
  }

  setEncounterSort = sort => {
    const view = this.state.worklistView
    const offset = 0
    const query = this.state.queryFilters
    this.setState({ encounterSort: sort }, () => {
      this.fetchEncounters(view, offset, query)
    })
    this.props.updateUserWorklistFilters(this.props.currentUser, {
      encounterSort: sort
    })
  }

  sortOnHeaderClick = clickedColumn => () => {
    let sort = this.state.encounterSort

    switch (clickedColumn) {
      case 'time':
        sort = (sort === 'start asc' ? 'start desc' : 'start asc')
        this.setEncounterSort(sort)
        break
      case 'appointmentType':
        sort = (sort === 'appointmentType asc' ? 'appointmentType desc' : 'appointmentType asc')
        this.setEncounterSort(sort)
        break
      case 'name':
        sort = (sort === 'lastName asc' ? 'lastName desc' : 'lastName asc')
        this.setEncounterSort(sort)
        break
      case 'dob':
        sort = (sort === 'birthDate asc' ? 'birthDate desc' : 'birthDate asc')
        this.setEncounterSort(sort)
        break
      case 'vbcProgram':
        sort = (sort === 'vbcProgram asc' ? 'vbcProgram desc' : 'vbcProgram asc')
        this.setEncounterSort(sort)
        break
      case 'openHccCount':
        sort = (sort === 'openHccCount desc' ? 'openHccCount asc' : 'openHccCount desc')
        this.setEncounterSort(sort)
        break
      case 'practitioner':
        sort = (sort === 'practitionerLastName asc' ? 'practitionerLastName desc' : 'practitionerLastName asc')
        this.setEncounterSort(sort)
        break
      case 'status':
        sort = (sort === 'statusCode asc' ? 'statusCode desc' : 'statusCode asc')
        this.setEncounterSort(sort)
        break
      case 'nameMobile':
        sort = (sort === 'lastName asc' ? 'lastName desc' : 'lastName asc')
        this.setEncounterSort(sort)
        break
      case 'lastReviewDate':
        sort = (sort === 'lastReviewDate asc' ? 'lastReviewDate desc' : 'lastReviewDate asc')
        this.setEncounterSort(sort)
        break
      default:
        sort = 'start asc'
        this.setEncounterSort(sort)
    }
  }

  onMore = () => {
    const view = this.state.worklistView
    const pages = this.state.pagesDisplayed
    const offset = LIST_PAGE_SIZE * pages + 1
    const query = this.state.queryFilters
    this.fetchEncounters(view, offset, query)
  }

  selectRecord = (id, event) => {
    if (this.props.onSelect && id) {
      this.props.onSelect(id, event)
    }
  }

  toggleFilter = (e, iconProps) => {
    this.setState({ showFilter: !this.state.showFilter })
  }

  onSubmitFilter = query => {
    const view = this.state.worklistView
    const offset = 0

    // update the filters in Redux, so we can remember
    this.props.updateUserWorklistFilters(
      this.props.currentUser,
      {
        start: query.start ? new Date(query.start) : null,
        birthDate: query.birthDate ? new Date(query.birthDate) : null,
        firstName: query.firstName ? query.firstName.replaceAll('%', '') : null,
        lastName: query.lastName ? query.lastName.replaceAll('%', '') : null,
        patientOrgId: query.patientOrgId ? query.patientOrgId : null,
        appointmentType: query.appointmentType ? query.appointmentType : null,
        practitionerId: query.practitionerId ? query.practitionerId : null,
        patientRegion: query.patientRegion ? query.patientRegion : null,
        vbcProgram: query.vbcProgram ? query.vbcProgram : null,
        potentialConditionCount: query.potentialConditionCount ? query.potentialConditionCount : null,
        specialty: query.specialty ? query.specialty : null,
        mrn: query.mrn ? query.mrn : null,
        view
      }
    )
    this.fetchEncounters(view, offset, query)
  }

  fetchEncounters = (view, offset, query) => {
    const { currentUser } = this.props
    const searchQuery = query || {}
    let encounterStatus = [ ENCOUNTER.STATUS.PLANNED ]
    const patientStatus = [ PATIENT.STATUS.ACTIVE ]
    let reviewCode = [ REVIEW.CODE.OPEN, REVIEW.CODE.READY_FOR_CDI_QA ]
    let reviewQueryStatus = null
    let lockStatus = null
    const sort = this.state.encounterSort
    switch (view) {
      case WORKLIST_VIEW.CDI.CANCELLED:
        reviewCode = [ REVIEW.CODE.OPEN, REVIEW.CODE.READY_FOR_CDI_QA ]
        encounterStatus = [ ENCOUNTER.STATUS.CANCELLED ]
        break
      case WORKLIST_VIEW.CDI.REVIEW_REQUEST:
        reviewQueryStatus = REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
        break
      case WORKLIST_VIEW.CDI.LOCKED:
        lockStatus = LOCK.STATUS.ACTIVE
        encounterStatus = [ ENCOUNTER.STATUS.PLANNED ]
        break
      case WORKLIST_VIEW.CDI.READY_FOR_CDI_QA:
        reviewCode = [ REVIEW.CODE.READY_FOR_CDI_QA ]
        encounterStatus = [ ENCOUNTER.STATUS.PLANNED ]
        break
      case WORKLIST_VIEW.CDI.READY_FOR_PHYSICIAN:
        reviewCode = [ REVIEW.CODE.READY_FOR_PHYSICIAN ]
        encounterStatus = [ ENCOUNTER.STATUS.PLANNED ]
        break
      case WORKLIST_VIEW.CDI.OPEN:
        reviewCode = [ REVIEW.CODE.OPEN ]
        encounterStatus = [ ENCOUNTER.STATUS.PLANNED ]
        break
      default:
        reviewCode = [ REVIEW.CODE.OPEN, REVIEW.CODE.READY_FOR_CDI_QA ]
        encounterStatus = [ ENCOUNTER.STATUS.PLANNED ]
    }

    if (!searchQuery.patientRegion) {
      const defaultRegions = currentUser.worklistFilters.defaultPatientRegion
      if (defaultRegions) {
        searchQuery.patientRegion = defaultRegions
      }
    }
    if (!searchQuery.onOffShore) {
      const offshoreFilter = currentUser.worklistFilters.defaultPatientOffshoreFilter
      if (offshoreFilter) {
        // the key 'onOffShore' is taken directly from EncounterRepo.scala
        searchQuery.onOffShore = offshoreFilter
      }
    }

    searchQuery.reviewCode = reviewCode
    searchQuery.encounterStatus = encounterStatus
    searchQuery.lockStatus = lockStatus
    searchQuery.patientStatus = patientStatus
    searchQuery.reviewQueryStatus = reviewQueryStatus

    const effectiveRole = useCdiRestricted(this.state.currentUser)
    const url = API_URL.ENCOUNTER_SEARCH_WITH_ROLE(effectiveRole)
    const limit = offset === 0 ? LIST_PAGE_SIZE + 1 : LIST_PAGE_SIZE
    const params = searchGenerator(searchQuery, limit, offset, sort)

    // finally, reach out to the backend
    api.get(url, { params }).then(({ data }) => {
      const results = offset === 0 ? data : concat(this.state.encounters, data)
      const pages = offset === 0 ? 1 : this.state.pagesDisplayed + 1
      this.setState({
        encounters: results,
        worklistView: view,
        pagesDisplayed: pages,
        encounterSort: sort,
        queryFilters: query
      })
    })
  }

  filterInitialValues() {
    const userFilters = this.props.currentUser.worklistFilters
    const initialValues = {}
    if (userFilters.start) {
      const start = format(new Date(userFilters.start), FORMAT.DATE)
      initialValues.start = start
    }
    if (userFilters.birthDate) {
      const birthDate = format(new Date(userFilters.birthDate), FORMAT.DATE)
      initialValues.birthDate = birthDate
    }
    initialValues.mrn = userFilters.mrn ? userFilters.mrn : null
    initialValues.firstName = userFilters.firstName ? userFilters.firstName : null
    initialValues.lastName = userFilters.lastName ? userFilters.lastName : null
    initialValues.patientOrgId = userFilters.patientOrgId ? userFilters.patientOrgId : null
    initialValues.appointmentType = userFilters.appointmentType ? userFilters.appointmentType : null
    initialValues.practitionerId = userFilters.practitionerId ? userFilters.practitionerId : null
    initialValues.patientRegion = userFilters.patientRegion ? userFilters.patientRegion : null
    initialValues.vbcProgram = userFilters.vbcProgram ? userFilters.vbcProgram : null
    initialValues.potentialConditionCount = userFilters.potentialConditionCount ? userFilters.potentialConditionCount : null
    initialValues.specialty = userFilters.specialty ? userFilters.specialty : null
    return initialValues
  }

  generateTableData(encounters) {
    if (!encounters || encounters.length === 0) return []

    const { currentUser } = this.props

    const formatPatientName = (view, patient, review) => {
      const name = `${patient.firstName} ${patient.lastName}`
      if (view === WORKLIST_VIEW.CDI.WORKLIST && review
        && review.reviewCode === REVIEW.CODE.OPEN) {
        return <span className="bold">{ name }</span>
      }
      return <span>{ name }</span>
    }

    const formatDate = (view, startDt, review) => {
      if (view === WORKLIST_VIEW.CDI.WORKLIST && review
        && review.reviewCode === REVIEW.CODE.OPEN) {
        return <span className="bold">{ format(startDt, FORMAT.DATE_TIME) }</span>
      }
      return <span>{ format(startDt, FORMAT.DATE_TIME) }</span>
    }

    const formatDateShort = lastReviewDt => {
      if (lastReviewDt) {
        return <span>{ format(lastReviewDt, FORMAT.DATE) }</span>
      }
      return <span>N/A</span>
    }

    return encounters.map(record => {
      const { worklistView } = this.state
      const { patient } = record
      const { practitioner } = record
      const { review } = record
      const practitionerName = `${practitioner.firstName} ${practitioner.lastName}`
      const mrn = patientMrn(patient, this.state.tenantId)
      const { vbcProgram } = patient
      const mobileName = `${patient.firstName} ${patient.lastName
      } ( ${format(patient.birthDate, FORMAT.DATE)} )`

      return {
        id: record.id,
        time: formatDate(worklistView, record.start, review),
        name: formatPatientName(worklistView, patient, review),
        dob: format(record.patient.birthDate, FORMAT.DATE),
        appointmentType: record.appointmentType,
        practitioner: practitionerName,
        mrn,
        openHccCount: record.openHccCount ? record.openHccCount : '0',
        vbcProgram,
        nameMobile: mobileName,
        lastReviewDate: formatDateShort(record.lastReviewDate),
        status: <ReviewStatusIcon reviewCode={ record.review.reviewCode } />,
        icon: <CdiEncounterStatusIcon encounter={ record } currentUser={ currentUser } />,
        meta: { className: 'cursor-pointer' }
      }
    })
  }

  leftSubHeader() {
    const prefix = 'CDI Worklist'
    switch (this.state.worklistView) {
      case WORKLIST_VIEW.CDI.CANCELLED:
        return `${prefix} - Cancelled Encounters`
      case WORKLIST_VIEW.CDI.LOCKED:
        return `${prefix} - Locked Encounters`
      case WORKLIST_VIEW.CDI.READY_FOR_CDI_QA:
        return `${prefix} - Ready for CDI QA`
      case WORKLIST_VIEW.CDI.READY_FOR_PHYSICIAN:
        return `${prefix} - Ready for Physician`
      case WORKLIST_VIEW.CDI.OPEN:
        return `${prefix} - Open Encounters`
      default:
        return prefix
    }
  }

  rightSubHeader() {
    const tooltip = this.state.showFilter
      ? 'Hide Worklist Filters' : 'Show Worklist Filters'
    return (
      <Popup content={ tooltip } mouseEnterDelay={ 1000 }
        trigger={
          <Icon id="worklist-filter" name="filter"
            size="large" className="pointer"
            onClick={ this.toggleFilter } />
        } />
    )
  }

  renderWorklistTable = () => {
    const tableData = this.generateTableData(this.state.encounters)
    const pages = this.state.pagesDisplayed
    const computerColumns = [
      { name: '', value: 'icon' },
      { name: 'Time', value: 'time', tooltip: 'Encounter Date and Time' },
      { name: 'Type', value: 'appointmentType', tooltip: 'Encounter Appointment Type' },
      { name: 'Name', value: 'name', tooltip: 'Patient Name' },
      { name: 'DOB', value: 'dob', tooltip: 'Patient Date of Birth' },
      { name: 'Practitioner', value: 'practitioner', tooltip: 'Encounter Practitioner' },
      { name: 'Open HCCs', value: 'openHccCount', tooltip: 'HCCs Not Addressed By Provider' },
      { name: 'Last Review', value: 'lastReviewDate', tooltip: 'Last Patient Review Date' },
      { name: 'Status', value: 'status', tooltip: 'Review Status' }
    ]

    if (showVbcDisplay(this.props.currentUser)) {
      computerColumns.splice(7, 0, { name: 'VBC Program', value: 'vbcProgram', tooltip: 'Patient VBC Program' })
    }

    let displayMore = false
    let displayMaxRecords = false

    if (tableData.length > pages * LIST_PAGE_SIZE) {
      displayMore = pages < LIST_MAX_PAGES
      displayMaxRecords = pages >= LIST_MAX_PAGES
      tableData.pop()
    }

    const table = (
      <Table
        key={ this.state.worklistView }
        computerColumns={ computerColumns }
        mobileColumns={ [
          { value: 'time', width: '4' },
          { value: 'nameMobile', width: '12' }
        ] }
        data={ tableData }
        onClick={ this.selectRecord }
        more={ displayMore }
        max={ displayMaxRecords }
        onMore={ this.onMore }
        sortOnHeaderClick={ this.sortOnHeaderClick }
      />
    )

    return <span>{ table }</span>
  }

  render() {
    return (
      <div className="header-padding">
        <Container>
          <SubHeader left={ this.leftSubHeader() } right={ this.rightSubHeader() } />
          { this.state.showFilter && (
            <Filter
              submit={ this.onSubmitFilter }
              filters={ this.filterMetadata() }
              initialValues={ this.filterInitialValues() } />
          ) }
          <Segment attached className="no-margin">
            <Grid>
              <Grid.Row style={ { paddingBottom: '0rem', textAlign: 'right' } }>
                <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  <Form.Field
                    label="View: "
                    control={ Select }
                    value={ this.state.worklistView }
                    options={ this.worklistViewOptions() }
                    onChange={ this.onWorklistViewSelect }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  { this.renderWorklistTable() }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), { updateUserWorklistFilters }
)(CdiWorklist)