export enum RoleType {
  Admin = 'ADMIN',
  CDI = 'CDI',
  CDIQAManager = 'CDI_QA_MANAGER',
  ClinicalAdmin = 'CLINICAL_ADMIN',
  Coder = 'CODER',
  CoderQAManager = 'CODER_QA_MANAGER',
  Physician = 'PHYSICIAN'
}

export type PatientRecord = {
  id: string
  reasonDescription: string
  time: Date
  mobileTime: Date
  meta: {
    className: string
  }
}

export type PatientScheduleModalTableData = {
  id: string
  reasonDescription: string
  start: Date
}

export const defaultSort = 'lastName asc'
export const cursorClass = 'cursor-pointer'
export const INITIAL_PAGES_DISPLAYED = 1

export const filterMetaData = [
  [
    {
      name: 'mrn',
      placeholder: 'MRN',
      width: 6,
      like: false
    },
    {
      name: 'firstName',
      placeholder: 'First Name',
      width: 5,
      like: true
    },
    {
      name: 'lastName',
      placeholder: 'Last Name',
      width: 5,
      like: true
    }
  ],
  [
    {
      name: 'birthDate',
      placeholder: 'Date of Birth',
      width: 6,
      like: true
    },
    {
      name: 'personalEmail',
      placeholder: 'Email',
      width: 5,
      like: true
    },
    {
      name: 'homePhone',
      placeholder: 'Phone',
      width: 5,
      like: true
    }
  ]
]

export const isTrue = (x: boolean): boolean => x
export const isFalse = (x: boolean): boolean => !x
