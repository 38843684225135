import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { routerMiddleware, routerReducer } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

import cdiQueryReducer from "../reducer/cdiQuery";
import conditionReducer from "../reducer/condition";
import encounterReducer from "../reducer/encounter";
import icdReducer from "../reducer/icd";
import patientReducer from "../reducer/patient";
import userReducer from "../reducer/user";
import coderReducer from "../reducer/coder"
import historyReducer from "../reducer/history";
import versionReducer from "../reducer/version";
import practitionerReducer from "../reducer/practitioner";

import { CLEAR_ALL_STATE } from "../reducer/user";

import crashReporter from "../middleware/crashReporter";
import logger from "../middleware/logger";

import * as Sentry from "@sentry/browser";

export const history = createBrowserHistory({
  basename: "/"
});

const persistedState = {
  user: localStorage.getItem("reduxState") ? JSON.parse(localStorage.getItem("reduxState")) : {}
};

//If the persisted state has a user associated, tell Sentry.
if (persistedState.user && persistedState.user.userId !== null && persistedState.user.isAuthenticated) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: persistedState.user.userId });
  });
}

const enhancers = [];

const middleware = [thunk, routerMiddleware(history), logger, crashReporter];

const appReducer = combineReducers({
  cdiQuery: cdiQueryReducer,
  condition: conditionReducer,
  encounter: encounterReducer,
  user: userReducer,
  coder: coderReducer,
  patient: patientReducer,
  router: routerReducer,
  icd: icdReducer,
  history: historyReducer,
  version: versionReducer,
  practitioner: practitionerReducer
});

// @see https://alligator.io/redux/reset-state-redux/
const rootReducer = (state, action) => {
  if (action.type === CLEAR_ALL_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
}

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

var store = createStore(rootReducer, persistedState, composedEnhancers);
store.subscribe(() => {
  const currentUser = store.getState().user;
  localStorage.setItem(
    "reduxState",
    JSON.stringify({
      isAuthenticated: currentUser.isAuthenticated,
      tenantId: currentUser.tenantId,
      userId: currentUser.userId,
      username: currentUser.username,
      alias: currentUser.alias,
      type: currentUser.type,
      roles: currentUser.roles,
      tenantProperties: currentUser.tenantProperties,
      userProperties: currentUser.userProperties,
      worklistFilters: currentUser.worklistFilters,
      appointmentlistFilters: currentUser.appointmentlistFilters
    })
  );
});
export default store;
