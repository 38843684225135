import { format } from 'date-fns'
import { curryN, replace } from 'ramda'
// Local
import { FORMAT, ROLES } from '../../constant'
import { EncounterConditionDisplay } from '../authenticated/types'
import { Patient } from '../patient/patient'
import { isCmsV28ConditionRule } from './conditionUtils'

/** *****************************************************************************
 * Provides helpers for formatting various encounter strings
 */

// format user role name
export function formatRoleName(role: keyof typeof ROLES) {
  if (role === ROLES.ADMIN) return 'Admin'
  if (role === ROLES.CDI) return 'CDI'
  if (role === ROLES.CDI_QA_MANAGER) return 'CDI QA Manager'
  if (role === ROLES.CLINICAL_ADMIN) return 'Clinical Admin'
  if (role === ROLES.CODER) return 'Coder'
  if (role === ROLES.CODER_QA_MANAGER) return 'Coder QA Manager'
  if (role === ROLES.PHYSICIAN) return 'Physician'
  return role
}

// formats date based on year match
export function formatEncounterHeaderDate(
  startDate: Date | string | number | undefined | null
) {
  if (!startDate) return ''
  return format(startDate, FORMAT.DATE_TIME)
}

// formats patient name, including birth-date (if defined)
export function formatPatientNameDob(patient: Patient | undefined) {
  if (!patient) return ''
  const name = `${patient.firstName} ${patient.lastName}`
  if (patient.birthDate) {
    const dob = format(patient.birthDate, FORMAT.DATE)
    return `${name} (${dob})`
  }
  return name
}

/**
 *
 * @param String inputString
 * @return String where only the first letter of each word is capitalized
 * NOTE: this could cause unexpected rendering for hyphenated last names, etc.
 * ex: inputString: Nancy DeVries ==> output: Nancy Devries
 */
export const initCap = (inputString: string): string =>
  inputString.toLowerCase().replace(/(?:^|\s)[a-z]/g, s => s.toUpperCase())

/**
 * @param icd10 ICD10 code
 * @return Raw, unformatted, ICD10 code
 */
export function rawIcd10Code(icd10: string | null): string {
  if (icd10 && icd10.length > 0) {
    return replace(/\./g, '', icd10).toUpperCase()
  }
  return ''
}

/**
 * @param icd10 ICD10 code ("raw" ICD10 after stripped from JSON string array)
 * @return Formatted ICD10 code or empty string if param is null or zero length
 */
export function formatIcd10Code(icd10: string | undefined | null): string {
  if (icd10 && icd10.length > 0) {
    if (icd10.includes('.')) {
      return icd10.toUpperCase()
    }
    const result =
      icd10.slice(0, 3) + (icd10.length > 3 ? `.${icd10.slice(3)}` : '')
    return result.toUpperCase()
  }
  return ''
}

/**
 * Formats ICD10 JSON as a comma delimited string of ICD10 codes.
 *
 * Historically an encounter condition can have multiple ICD10 codes that are
 * stored as a JSON array. In practice, we always have an array with a single
 * value
 *
 * @param icd10Json JSON array of ICD10 codes or ICD10 code string
 * @return Array of formatted ICD10 codes or empty array if parameter is null
 */
export function formatIcd10Json(
  icd10Json: string[] | undefined | string | null
): string[] {
  if (icd10Json) {
    if (Array.isArray(icd10Json)) {
      return icd10Json
        .map(icd10 => formatIcd10Code(icd10))
        .filter(v => v && v.length > 0)
    }
    return [ formatIcd10Code(icd10Json) ]
  }
  return []
}

/**
 * Formats a condition title for a CDI or Coder
 *
 * @param condition an encounter condition
 * @return a string of this condition formatted
 */
export function formatConditionTitle(condition: EncounterConditionDisplay) {
  const isCmsV28Condition = isCmsV28ConditionRule(condition)
  const shouldShowIcd10 =
    condition &&
    condition.icd10CodeJson &&
    condition.conditionStatus !== 'POTENTIAL'
  const template = shouldShowIcd10
    ? `${condition.title} ${formatIcd10Json(condition.icd10CodeJson)}`
    : `${condition.title}`
  
  return isCmsV28Condition ? `${template} [CMS v28]` : template
}

/**
 * Formats a condition title for a Clinical Admin or Practitioner
 *
 * @param condition an encounter condition
 * @param props the current props
 * @return a string of this condition formatted
 */
type Props = {
  hccChild: boolean
}
export function formatConditionTitleExt(
  condition: EncounterConditionDisplay,
  props: Props
) {
  const formatted = formatIcd10Json(condition.icd10CodeJson)
  const isCmsV28Condition = isCmsV28ConditionRule(condition)
  const shouldShowIcd10 =
    condition &&
    condition.icd10CodeJson &&
    condition.conditionStatus !== 'POTENTIAL'
  let template
  if (props.hccChild === true) {
    // if (formatted.length === 0) {
    //   return condition.title
    // }
    template = formatted.length === 0 ? condition.title : `${formatted[0]} - ${condition.title}`
    return isCmsV28Condition ? `${template} [CMS v28]` : template
  }
  if (formatted.length !== 0 && shouldShowIcd10) {
    template = `${condition.title} (${formatted[0]})`
    return isCmsV28Condition ? `${template} [CMS v28]` : template
  }
  return isCmsV28Condition ? `${condition.title} [CMS v28]` : condition.title
}

/**
 * Formats a risk code title and risk code. Supports Medicaid and CMS HCC codes
 * If the code looks like a Medicaid code, just return RA
 *
 * @param riskCode - the HCC or risk code
 */
export function displayRiskCode(riskCode: string): string {
  if (riskCode) {
    if (
      riskCode.trim().toUpperCase().startsWith('RA-') ||
      riskCode.trim().toUpperCase().startsWith('XA-')
    ) {
      // looks like Medicaid
      return 'RA'
    }
    return `HCC ${riskCode}`
  }
  return ''
}

export const descriptionTruncated = (
  numberOfCharactersAllowed: number,
  x: string
): string => x.substring(0, numberOfCharactersAllowed)

export const truncatedDescription = curryN(2, descriptionTruncated)
